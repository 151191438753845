import React from 'react'
// import { Web3Provider } from './src/contexts/Web3Context'
import './src/styles/global.css'

export function wrapRootElement({ element }) {
	return (
		// <Web3Provider>
		<>
			{element}
		</>
		// </Web3Provider>
	)
}
